<template>
  <div class="outer-wrapper">
    <div class="title-wrapper">
      <span>备份还原</span>
    </div>
    <div class="inner-wrapper">
      <div class="editor-wrapper">
        <Tabs>
          <TabPane label="现有备份" icon="ios-warning">
            <query-table
              :columnsFormatData="columnsFormatData"
              askData="backupManage"
              :refreshData="update_count"
              main_width="750px"
            ></query-table>
          </TabPane>
        </Tabs>
      </div>
      <div class="preview-wrapper">
        <Tabs>
          <TabPane label="新增备份点" icon="ios-add-circle-outline">
            <div class="form-wrapper">
              <Form
                ref="addBackup"
                :model="newBackup"
                :rules="ruleValidate"
                :label-width="150"
              >
                <FormItem label="备份名称：" prop="name">
                  <Input v-model="newBackup.name" placeholder="" />
                </FormItem>
                <FormItem label="备份详情说明：" prop="description">
                  <Input
                    v-model="newBackup.description"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 5 }"
                    placeholder=""
                  />
                </FormItem>
                <FormItem>
                  <Button type="primary" @click="handleSubmit('addBackup')"
                    >提交</Button
                  >
                  <Button
                    @click="handleReset('addBackup')"
                    style="margin-left: 8px"
                    >重置</Button
                  >
                </FormItem>
              </Form>
              <Spin fix v-show="loadingStatus"></Spin>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>
    <Modal
      v-model="scModal"
      width="800"
      :mask-closable="false"
      @on-cancel="modal_cancel"
    >
      <p slot="header" class="sc-header">
        <Icon type="ios-list-box" />
        <span>备份信息修改</span>
      </p>
      <div class="sc-content-wrapper">
          <div class="form-wrapper">
              <Form
                ref="alterBackup"
                :model="currentBackup"
                :rules="ruleValidate"
                :label-width="150"
              >
                <FormItem label="备份名称：" prop="name">
                  <Input v-model="currentBackup.name" placeholder="" />
                </FormItem>
                <FormItem label="备份详情说明：" prop="description">
                  <Input
                    v-model="currentBackup.description"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 5 }"
                    placeholder=""
                  />
                </FormItem>
              </Form>
          </div>
      </div>
      <div slot="footer">
        <Button size="large" @click="modal_save('alterRisk')">确定</Button>
        <Button size="large" @click="modal_cancel">取消</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
import queryTable from "@/components/Common/QueryTable.vue";

let userToken = localStorage.getItem("current_user_token");

function backupFac() {
  return {
    id:0,
    name:'',
    description:''
  };
}

export default {
  name: "bakcupManage",
  components: {
    queryTable,
  },
  props: {},
  data() {
    return {
      update_count: 0,
      columnsFormatData: [
        {
          title: "备份名称",
          key: "name",
          align: "center",
        },
        {
          title: "备份详情说明",
          key: "description",
          align: "center",
        },
        {
          title: "备份时间",
          key: "op_time",
          align: "center",
          width:'100'
        },
        {
          title: "备份人",
          key: "user_name",
          align: "center",
          width:'85'
        },
        {
          title: "操作",
          align: "center",
          width: 180,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { color: "green" },
                  on: {
                    click: () => {
                      this.itemEdit(params.row);
                    },
                  },
                },
                "修改"
              ),
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { color: "red", marginLeft: "5px" },
                  on: {
                    click: () => {
                      this.confirmBox(params.row);
                    },
                  },
                },
                "删除"
              ),
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { color: "blue", marginLeft: "5px" },
                  on: {
                    click: () => {
                      this.exeConfirmBox(params.row);
                    },
                  },
                },
                "还原"
              ),
            ]);
          },
        },
      ],
      loadingStatus: false,
      newBackup: backupFac(),
      currentBackup:backupFac(),
      ruleValidate: {
        
      },
      scModal: false,
    };
  },
  watch: {},
  computed: {},
  created() {
  },
  methods: {
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          let form = new FormData();
          form.append("userToken", userToken);
          form.append("askData", "addBackup");
          form.append("newBackup", JSON.stringify(this.newBackup));
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          this.$axios
            .post(this.$url + "/backup_alter", form, config)
            .then((response) => {
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success("备份信息添加成功！");
                this.update_count++;
                this.handleReset(name);
              } else {
                this.$Message.error(response.data.error_info);
              }
            })
            .catch((error) => {
              this.loadingStatus = false;
              this.$Message.error(error + "未知错误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });
    },
    modal_save(name) {
        this.$refs[name].validate((valid) => {
        if (valid) {
          let form = new FormData();
          form.append("userToken", userToken);
          form.append("askData", "alterBackup");
          form.append("currentBackup", JSON.stringify(this.currentBackup));
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          this.$axios
            .post(this.$url + "/backup_alter", form, config)
            .then((response) => {
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success("备份信息修改成功！");
                this.update_count++;
                this.scModal = false;
              } else {
                this.$Message.error(response.data.error_info);
              }
            })
            .catch((error) => {
              this.loadingStatus = false;
              this.$Message.error(error + "未知错误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });
    },
    modal_cancel() {
      this.scModal = false;
    },
    confirmBox(value) {
      this.$Modal.confirm({
        title: "确认删除该条备份信息：",
        content:
          "<p>" +
          "名称：" +
          value.name +
          "</p>" +
          "<p>" +
          "备份时间：" +
          value.op_time +
          "</p>",
        onOk: () => {
          this.delItem(value.id);
        },
        onCancel: () => {},
      });
    },
    exeConfirmBox(value) {
      this.$Modal.confirm({
        title: "确认按该条备份信息进行数据还原：(最好在还原前先备份当前信息)",
        content:
          "<p>" +
          "名称：" +
          value.name +
          "</p>" +
          "<p>" +
          "备份时间：" +
          value.op_time +
          "</p>",
        onOk: () => {
          this.exeItem(value.id);
        },
        onCancel: () => {},
      });
    },
    delItem(id) {
      this.$axios
        .post(this.$url + "/backup_alter", {
          userToken: userToken,
          askData: "delBackup",
          pid: id,
        })
        .then((response) => {
          if (response.data.stat === "ok") {
            this.$Message.success("删除成功！");
            this.update_count++;
          }
        })
        .catch((error) => {
          this.$Message.warning(error + "网络错误！操作失败。");
        });
    },
    exeItem(id) {
      this.$axios
        .post(this.$url + "/backup_alter", {
          userToken: userToken,
          askData: "exeBackup",
          pid: id,
        })
        .then((response) => {
          if (response.data.stat === "ok") {
            this.$Message.success("还原成功！");
            this.update_count++;
          }
        })
        .catch((error) => {
          this.$Message.warning(error + "网络错误！操作失败。");
        });
    },
    itemEdit(value){
        this.currentBackup = backupFac();
        this.currentBackup.id = value.id;
        this.currentBackup.name = value.name;
        this.currentBackup.description = value.description;
        this.scModal = true;
    }
  },
};
</script>
<style scoped>
.inner-wrapper {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  min-height: 700px;
}

.title-wrapper {
  text-align: center;
  font-size: 20px;
  padding: 16px;
}

.editor-wrapper,
.preview-wrapper {
  margin: 0px 20px 0px;
  width: 750px;
  font-size: 14px;
  text-align: center;
  padding: 10px 0;
}

.form-wrapper {
  text-align: left;
  border: 1px solid #eee;
  padding: 80px 120px 0px 50px;
  min-height: 600px;
  position: relative;
}

.sc-content-wrapper {
  padding: 0 50px;
  color: #333;
  font-size: 14px;
}
.choosed-product-wrapper {
  min-height: 100px;
  padding: 10px 20px;
  border: 1px solid #eee;
  box-shadow: 1px 1px 1px #eee;
  margin: 20px 0;
}

.sc-content-wrapper td:first-child {
  text-align: right;
  padding-right: 10px;
}

.sc-content-wrapper td {
  height: 45px;
  color: #777;
}

.sub-content-wrapper {
  margin: 10px 0;
  border: 1px solid #eee;
  position: relative;
}

.sub-content-title {
  position: absolute;
  left: 0;
  overflow: hidden;
  height: 100%;
  writing-mode: vertical-lr;
  text-align: center;
  background: #f5f5f5;
  letter-spacing: 10px;
  text-indent: 10px;
  padding: 12px 10px;
}

.table-wrapper {
  padding: 22.5px 20px;
  margin-left: 50px;
}

.fast-button {
  cursor: pointer;
}

input::-webkit-input-placeholder {
  color: #ccc;
  text-align: center;
}

input:-ms-input-placeholder {
  color: #ccc;
  text-align: center;
}

input::-moz-placeholder {
  color: #ccc;
  text-align: center;
}
tr.modal-product-choosed-tr {
  border-bottom: 1px solid #ddd;
}

tr.modal-product-choosed-tr:last-child {
  border: none;
}
</style>